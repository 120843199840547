


<template>
<div class="preview">
  <content-preview v-if="previewLoaded" :settings="pageSettings"></content-preview>
  <div v-if="loadError">
    <h1 class="pa-7">Preview unavailable</h1>
  </div>
  <div class="loading" v-if="!loadError && !previewLoaded">
    <v-progress-circular
        indeterminate
        size="70"
        width="8"
        color="primary"
    ></v-progress-circular>
  </div>
</div>
</template>

<script>
import ContentPreview from "@/components/ContentPreview";
export default {
    name: "preview",
  components: {ContentPreview},
  mounted() {
    if(this.$route.query.settings){
      //this.$store.state.sessionId = this.$route.query.settings;
      this.$store.dispatch("loadPreview", this.$route.query.settings).then((record) => {
        if(record.data){
          this.$store.state.settings = record.data;
          this.previewLoaded = true;
        }else{
          this.loadError = true;
        }
      }).catch((error) => {
        console.log(error);
        this.loadError = true;
      })
    }else{
      this.loadError = true;
    }
  },
  props: {},
    data: function(){
        return {
          previewLoaded: false,
          loadError: false
        }
        },
    methods: {},
    computed: {
      pageSettings: function(){
        return this.$store.state.settings;
      }
    }
}
</script>

<style scoped>


.preview {
  overflow-y: scroll;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
