


<template>
<div class="products">
<div class="page-title pl-10 pt-5">Campaigns</div>
  <div class="row ma-0">
    <div class="campaign-list flex-grow-0 flex-shrink-0 col">
      <div class="campaign-item px-10 py-2 selected"> {{ settings.campaign.name }} <div class="tooltip"><i aria-hidden="true" class="v-icon notranslate mdi mdi-information theme--light" style="color: rgb(204, 194, 0); caret-color: rgb(204, 194, 0);"></i>
        <div class="tooltip-ctn pa-4">
          <span><strong>Products</strong> and their QR codes are organized by <strong>Campaigns</strong>. Campaigns enable you to create settings which apply to all products and codes within them. If you need more campaigns, please contact our sales team.</span>
        </div>
      </div>
    </div>
    <div class="campaign-skeleton-ctn" style="display: none;">
      <div aria-busy="true" aria-live="polite" role="alert" class="v-skeleton-loader v-skeleton-loader--is-loading theme--light" style="width: 100%;">
        <div class="v-skeleton-loader__list-item v-skeleton-loader__bone">
          <div class="v-skeleton-loader__text v-skeleton-loader__bone"></div>
        </div>
      </div>
    </div>
    </div>
    <div class="product-list flex-grow-1 col">
      <div class="edit-title pl-4 ">Edit Your Products</div>
      <div class="pl-4 mb-4">Some of the info below will be displayed to customers. Edit your product(s) to learn more.</div>
      <div v-if="loaded" class="product-card">
        <div class="row ma-0">
          <div class="product-picture flex-grow-0 pa-0 col" :style="thumbnailStyle"></div>
          <div class="flex-grow-1 pa-0 pl-3 col">
            <div class="product-title font-weight-medium">{{  settings.product.name }}</div>
            <div class="product-description">SKU: {{ settings.product.sku }}</div>
            <div class="product-description">Product URL: {{ settings.product.url }}</div>
          </div>
          <div class="flex-grow-0 col">
            <div class="relative">
            <button type="button" @click="editProduct" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default">
              <span class="v-btn__content">
                <i aria-hidden="true" class="v-icon notranslate mdi mdi-pencil theme--light" style="color: rgb(21, 187, 240); caret-color: rgb(21, 187, 240);"></i>
              </span>

            </button>
            <tutorial-dialog :task="0" :step="2" position="bottom" align="right">Click the edit icon to make changes.</tutorial-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="900px"
        style="overflow-y: visible;"
    >

      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="pt-5">
              <v-col class="flex-grow-0 pa-0 ma-0 relative">
                <div class="image-upload-ctn pr-5"><div class="d-flex flex-row align-center mb-3">
                  <img :src="settings.product.image"></div>
                  <div>
                    <v-btn class="" color="primary" @click="onUpload">Upload Image</v-btn>
                    <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/png, image/jpeg"
                        @change="onFileChanged"
                    >
                    <div class="font-weight-light hint pt-1"> Supported Formats: PNG or JPG<br>Max 2mb </div>
                    <v-dialog
                        v-model="uploadPreview"
                        persistent
                        max-width="700px"
                    >

                      <v-card>
                        <v-card-title class="headline">
                          Image Upload
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row class="">
                              <v-col class="pa-0">
                                <v-img :src="previewURL"></v-img>
                              </v-col>

                            </v-row>

                          </v-container>

                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="uploadPreview = false"
                          >
                            Close
                          </v-btn>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="saveImage"
                              :loading="isSelecting"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
              </v-col>
              <v-col>
                <v-row>
                  <v-col class="pt-0 relative">
                    <v-text-field
                        label="Product Name"
                        required
                        hint="Will be visible to customers on the product’s landing page."
                        persistent-hint
                        v-model="settings.product.name"
                        @change="($store.state.unsavedChanges = true)"
                    ></v-text-field>

                  </v-col>
                  <v-col class="pt-0">
                    <v-text-field
                        label="Product SKU"
                        hint="Internal use only, not visible to customers."
                        persistent-hint
                        v-model="settings.product.sku"
                        @change="($store.state.unsavedChanges = true)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="relative">
                    <v-text-field
                        label="Product URL"
                        hint="The Product URL can be used later for QR code redirection. Each product can have their own URL."
                        persistent-hint
                        v-model="settings.product.url"
                        @change="($store.state.unsavedChanges = true)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeEditProduct"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="closeEditProduct"
              :loading="isSelecting"
          >
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
      <tutorial-dialog :task="0" :step="3" position="bottom-left" class="mt-4" max-width="400px">You can upload a custom product picture and edit the product details here.</tutorial-dialog>

    </v-dialog>
  </v-row>
</div>
</template>

<script>
import axios from 'axios'
import TutorialDialog from "@/components/TutorialDialog";

export default {
    name: "products",
  components: {TutorialDialog},
  props: {},
  mounted() {
      // if(!this.$store.state.loaded){
      //   this.$store.dispatch("loadSession");
      // }

      this.$store.dispatch("triggerStep", { task:0, step: 2});
  },
  data: function(){
        return {
          dialog: false,
          isSelecting: false,
          selectedFile: null,
          previewURL: "",
          uploadPreview: false
        }
        },
    methods: {
      editProduct: function(){
        this.dialog = true;
        setTimeout(()=>{
          this.$store.dispatch("triggerStep",{ task: 0, step: 3 } )
        },500)

      },



      closeEditProduct: function(){
        this.dialog = false;
        if((this.$store.state.demo.task === 0) && (this.$store.state.demo.step >= this.$store.getters.currentTask.steps)){
          console.log("complete task");

          this.$store.getters.currentTask.completed = true;
          this.$store.dispatch("setTask",{ task: 1, step: 1 } )
        }else{
          this.$store.dispatch("triggerStep",{ task: 0, step: 4 } )
        }
      },

      onUpload: function(){
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileChanged: function(e){
        let file = e.target.files[0];
        var MAX_FILE_SIZE = 2 * 1024 * 1024;
        if(file.size > MAX_FILE_SIZE){
          alert("This file is too large. Select a smaller file.");
          return;
        }

        this.selectedFile = e.target.files[0]
        this.previewURL = URL.createObjectURL(this.selectedFile)
        this.uploadPreview = true;
      },
      saveImage: async function(){
        let uploadURL = await this.uploadPhoto();
        console.log(uploadURL);
        if(uploadURL){
          this.$store.state.unsavedChanges = true;
          this.settings.product.image = uploadURL;
        }else{
          alert("Error");
        }

        this.uploadPreview = false;
      },
      uploadPhoto:  async function () {
        if(!this.selectedFile){ return }
        const file = this.selectedFile;

        //const filename = encodeURIComponent(file.name);
        const fileType = encodeURIComponent(file.type);
        console.log(fileType);
        const res = await fetch(`/api/upload_put?type=${fileType}`);
        console.log(res);
        const { url, key } = await res.json();
        //const formData = new FormData();

        // Object.entries({ ...fields, file }).forEach(([key, value]) => {
        //   formData.append(key, value);
        // });

        //let headers = new Headers();
        console.log(url, key);
        let putConfig = {
          headers: {
            'x-amz-acl':'public-read',
            'Content-Type': file.type
          }
        }

        const upload = await axios.put(url, file, putConfig );

        console.log(upload.data);


        if (upload.statusText == 'OK') {
          console.log('Uploaded successfully!', upload);
          return url.split('?')[0];
        } else {
          console.error('Upload failed.');
          return false;
        }


      }

    },
    computed: {
      loaded: function(){
        return this.$store.state.loaded;
      },
      settings: function(){
        return this.$store.state.settings;
      },
      thumbnailStyle: function(){
        return {
          backgroundImage: `url(${this.settings.product.image})`
        }
      }
    }
}
</script>

<style scoped>


.products {
  background: #fafafa;
}

.page-title {
  font-size: 1.7rem;
  color: rgba(0,0,0,.5);
}

.campaign-list {
  padding: 0;
  border-right: 1px solid rgba(51,51,51,.3);
  min-width: 300px;
  padding-top: 15px;
}

.campaign-item {
  cursor: pointer;
}

.campaign-item.selected {
  background-color: rgba(21,187,240,.3)!important;
}

.tooltip {
  display: inline-block;
  position: relative;
  width: 30px;
}

.tooltip-ctn {
  display: none;
  font-family: Roboto;
  font-size: 13px;
  left: 30px;
  bottom: 5px;
  position: absolute;
  background: #fff!important;
  color: #0789b2!important;
  opacity: 1!important;
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 8px 56px 0 rgb(0 0 0 / 35%);
  z-index: 99;
}

.tooltip:hover .tooltip-ctn {
  display: block;
}

.product-list {
  padding-top: 14px;
  padding-left: 25px;
}

.edit-title {
  color: #333;
  font-weight: 700;
  font-size: 17px;
}

.product-card {
  margin-bottom: 2px;
  max-width: 800px;
  background: #fff;
}

.product-picture {
  min-width: 100px;
  min-height: 100px;
  background-size: cover;
}

.product-title {
  font-size: 16px;
  padding-top: 5px;
  letter-spacing: .15px;
  line-height: 1.5;
  color: rgba(51,51,51,.87);
}

.product-description {
  font-size: 14px;
  color: rgba(51,51,51,.6);
}

.image-upload-ctn {
  text-align: center;
}

.image-upload-ctn img {
  width: 200px;
}

.hint {
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}


</style>
